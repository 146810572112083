const localStorageMethods = {
  CreateCart() {
    if (!localStorage.getItem('cart')) {
      const cart = {
        CannotContinue: false,
        FinalPrice: 0,
        Id: 0,
        Products: [],
        TotalPrice: 0,
      };
      localStorage.setItem('cart', JSON.stringify(cart));
      window.dispatchEvent(new Event('CartChangedEventLocalStorage'));
    }
  },
  SetCart(cart) {
    cart.Products.forEach((item) => {
      if (item.Configurations === '') {
        // eslint-disable-next-line no-param-reassign
        item.Configurations = [];
      }
    });
    localStorage.setItem('cart', JSON.stringify(cart));
    window.dispatchEvent(new Event('CartChangedEventLocalStorage'));
  },
  ChangeProductConfigByUniqueId(uniqueId, quantity) {
    const cart = this.GetCart();
    const idx = cart.Products.findIndex((prod) => prod.UniqueId === uniqueId);
    if (cart.Products[idx].Quantity + quantity > 0) {
      // eslint-disable-next-line no-param-reassign
      cart.Products[idx].Quantity = quantity;
      // eslint-disable-next-line operator-assignment
      cart.Products[idx].Price = cart.Products[idx].PriceForOneItem * quantity;
    }
    this.CalculateCartTotalPrice(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
    window.dispatchEvent(new Event('CartChangedEventLocalStorage'));
  },
  ChangeProductQuantityFromCart(id, quantity) {
    const cart = this.GetCart();
    const idx = cart.Products.findIndex((prod) => prod.Id === id);
    if (cart.Products[idx].Quantity + quantity > 0) {
      // eslint-disable-next-line no-param-reassign
      cart.Products[idx].Quantity = quantity;
      // eslint-disable-next-line operator-assignment
      cart.Products[idx].Price = cart.Products[idx].PriceForOneItem * quantity;
      cart.Products[idx].Price = Number(cart.Products[idx].Price.toFixed(2));
    }
    this.CalculateCartTotalPrice(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
    window.dispatchEvent(new Event('CartChangedEventLocalStorage'));
  },
  ChangeProductQuantityFromProduct(id, quantity, finalPrice) {
    const cart = this.GetCart();
    const idx = cart.Products.findIndex((prod) => prod.Id === id);
    if (cart.Products[idx].Quantity + quantity > 0) {
      // eslint-disable-next-line no-param-reassign
      cart.Products[idx].Quantity = quantity;
      // eslint-disable-next-line operator-assignment
      cart.Products[idx].Price = Number(finalPrice.toFixed(2));
      cart.Products[idx].HasChanged = false;
    }
    this.CalculateCartTotalPrice(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
    window.dispatchEvent(new Event('CartChangedEventLocalStorage'));
  },
  AddProductToCart(product) {
    this.CreateCart();
    const cart = JSON.parse(localStorage.getItem('cart'));
    // eslint-disable-next-line no-param-reassign
    product.Id = cart.Products.length + 1;
    // eslint-disable-next-line no-param-reassign
    product.Price = Number(product.Price.toFixed(2));
    cart.Products.push(product);
    this.CalculateCartTotalPrice(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
    window.dispatchEvent(new Event('CartChangedEventLocalStorage'));
  },
  RemoveProductFromCart(cartItemId) {
    const cart = JSON.parse(localStorage.getItem('cart'));
    const idx = cart.Products.findIndex((prod) => prod.Id === cartItemId);
    cart.Products.splice(idx, 1);
    this.CalculateCartTotalPrice(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
    window.dispatchEvent(new Event('CartChangedEventLocalStorage'));
  },

  ClearCart() {
    const cart = JSON.parse(localStorage.getItem('cart'));
    cart.Products.splice(0, cart.Products.length);
    this.CalculateCartTotalPrice(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
    window.dispatchEvent(new Event('CartChangedEventLocalStorage'));
  },

  CalculateCartTotalPrice(cart) {
    // eslint-disable-next-line no-param-reassign
    cart.TotalPrice = 0;
    cart.Products.forEach((prod) => {
      // eslint-disable-next-line no-param-reassign
      cart.TotalPrice += prod.Price;
    });
    // eslint-disable-next-line no-param-reassign
    cart.FinalPrice = cart.TotalPrice;
  },
  GetCart() {
    this.CreateCart();
    return JSON.parse(localStorage.getItem('cart'));
  },
  GetProductById(id) {
    const cart = this.GetCart();
    const idx = cart.Products.findIndex((prod) => prod.ProductId === id);
    return idx >= 0 ? cart.Products[idx] : null;
  },
  GetProductWithConfigByUniqueId(id) {
    const cart = this.GetCart();
    const idx = cart.Products.findIndex((prod) => prod.UniqueId === id);
    return idx >= 0 ? cart.Products[idx] : null;
  },
  DeleteCart() {
    if (localStorage.getItem('cart')) {
      const cart = {
        CannotContinue: false,
        FinalPrice: 0,
        Id: 0,
        Products: [],
        TotalPrice: 0,
      };
      localStorage.setItem('cart', JSON.stringify(cart));
      window.dispatchEvent(new Event('CartChangedEventLocalStorage'));
    }
  },
};
export default localStorageMethods;
