<!-- eslint-disable vue/no-mutating-props -->
<template>
  <Form :validation-schema="schema" @submit="ChangePassword" ref="changePasswordModalRef">
    <div
      class="modal fade"
      id="changePasswordModal"
      tabindex="-1"
      aria-labelledby="changePasswordModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="heading-06 text-neutral-07 text-center mb-32px">Schimbă parola</div>
          <div class="modal-body">
            <div class="mt-3">
              <label for="password_change" class="text-neutral-07 text-button-02 mb-2"
                >Parola nouă</label
              >
              <div class="input-group right">
                <Field
                  v-model="user.Password"
                  :type="[showPassword ? 'text' : 'password']"
                  name="password_change"
                  id="password_change"
                  placeholder="Parola"
                  class="form-control"
                  autocomplete="on"
                />
                <span
                  class="input-group-text"
                  @pointerdown="showPassword = true"
                  @pointerup="showPassword = false"
                >
                  <img src="../../assets/images/icons/eye-linear-20x20.svg"  v-if="showPassword"/>
                  <img src="../../assets/images/icons/eye-slash-linear-20x20.svg" v-else/>
                </span>
              </div>
              <ErrorMessage name="password_change" class="error-message" />
            </div>

            <div class="mt-3">
              <label for="confirm_password_change" class="text-neutral-07 text-button-02 mb-2"
                >Confirmă parola</label
              >
              <div class="input-group right">
                <Field
                  v-model="user.ConfirmPassword"
                  :type="[showConfirmedPassword ? 'text' : 'password']"
                  name="confirm_password_change"
                  id="confirm_password_change"
                  placeholder="Parola"
                  class="form-control"
                  autocomplete="on"
                />
                <span
                  class="input-group-text"
                  @pointerdown="showConfirmedPassword = true"
                  @pointerup="showConfirmedPassword = false"
                >
                  <img src="../../assets/images/icons/eye-linear-20x20.svg"  v-if="showConfirmedPassword"/>
                  <img src="../../assets/images/icons/eye-slash-linear-20x20.svg" v-else/>
                </span>
              </div>
              <ErrorMessage name="confirm_password_change" class="error-message" />
            </div>
            <button class="button btn-medium-wider btn-primary-solid d-block w-100 mt-3">
              Schimbă parola
            </button>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Field, ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'ChangePasswordModal',
  props: {
    user: {},
  },
  data() {
    return {
      showPassword: false,
      showConfirmedPassword: false,
    };
  },
  methods: {
    ChangePassword() {
      this.$axios
        .post('/api/Auth/resetPassword', this.user)
        .then(() => {
          this.$swal
            .fire('Success!', 'Password has been changed succesfuly', 'success')
            .then(() => {
              this.$utils.ToastNotify('error', 'Something went wrong.');
              // eslint-disable-next-line no-undef
              $('#changePasswordModal').modal('show');
            });
        })
        .catch(() => {
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    ClearModal() {
      this.showPassword = false;
      this.showConfirmedPassword = false;
      this.$refs.changePasswordModalRef.resetForm();
    },
  },
  components: {
    Field,
    ErrorMessage,
    Form,
  },
  computed: {
    schema() {
      return yup.object({
        password_change: yup
          .string()
          .min(3)
          .required('Parola este obligatorie')
          .test(
            'password-complecity',
            'Parola trebuie sa contina macar o majuscula, un caracter special si o cifra',
            (password) => {
              const specialCharRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~]/;
              const numberRegex = /[0-9]/;
              const uppercaseRegex = /[A-Z]/;
              return (
                specialCharRegex.test(password) &&
                numberRegex.test(password) &&
                uppercaseRegex.test(password)
              );
              // eslint-disable-next-line comma-dangle
            }
          ),
        confirm_password_change: yup
          .string()
          .required('Va rugam confirmati parola')
          .test(
            'check-password',
            'Parolele nu se potrivesc',
            // eslint-disable-next-line comma-dangle
            (confirmPassword) => confirmPassword === this.user.Password
          ),
      });
    },
  },
};
</script>

<style scoped>
.b-color-check {
  background-color: #ff0040 !important;
  border-color: #ff0040;
}

.form-check-input:checked {
  background-color: #ff0040;
  border-color: #ff0040;
}

.text-container {
  position: relative;
  overflow: hidden;
}

.show-passoword {
  position: absolute;
  top: 7px;
  right: 5px;
  transition: right 0.2s;
}

.show {
  right: 5px;
}
</style>
