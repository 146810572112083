/* eslint-disable */

function initFacebookSdk() {
  const facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID;
  const response = new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the vue app
    window.fbAsyncInit = function () {
      const { FB } = window;
      FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v18.0',
      });

      // // auto authenticate with the api if already logged in with facebook
      FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          resolve();
        } else {
          resolve();
        }
      });
    };
    // load facebook sdk script
    (function (d, s, id) {
      let js; const
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      // eslint-disable-next-line prefer-const
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  });
  return response;
}

// function initFacebookSdk() {
//   const facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID;
//   const response = new Promise((resolve) => {
//     // auto authenticate with the api if already logged in with facebook
//     window.fbAsyncInit = function () {
//       const { FB } = window;
//       FB.init({
//         appId: facebookAppId,
//         cookie: false,
//         xfbml: true,
//         version: 'v18.0',
//       });
//       FB.getLoginStatus(function (response) {
//         if (response.status === 'connected') {
//           //user is logged in
//         } else {
//           window.location = encodeURI(
//             'https://www.facebook.com/dialog/oauth?client_id=' +
//               facebookAppId +
//               '&redirect_uri=' +
//               'http://localhost:8080/' +
//               '&response_type=token'
//           );
//         }
//       });
//     };
//     (function (d) {
//       var js,
//         id = 'facebook-jssdk',
//         ref = d.getElementsByTagName('script')[0];
//       if (d.getElementById(id)) {
//         return;
//       }
//       js = d.createElement('script');
//       js.id = id;
//       js.async = true;
//       js.src = '//connect.facebook.net/en_US/all.js';
//       ref.parentNode.insertBefore(js, ref);
//     })(document);
//   });
//   return response;
// }

export default initFacebookSdk;
