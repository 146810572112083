<template>
  <div class="product-loader-card">
    <div class="body">
      <!-- <div class="rating"></div> -->
    </div>
    <div class="category mt-3"></div>
    <div class="title mt-2 pt-1"></div>
    <div class="description mt-3"></div>
    <div class="price mt-2"></div>
    <div class="buttons mt-4 d-md-none"></div>
  </div>
</template>
<style scoped>
.buttons {
  background-color: #ededed;
  height: 32px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.buttons::after {
  -webkit-animation: ph-animation 0.8s linear infinite;
  animation: ph-animation 0.8s linear infinite;
  /* background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, 0.35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%; */
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 46%,
      hsl(0deg 0% 91.97% / 35%) 50%,
      hsla(0, 0%, 100%, 0) 54%
    )
    50% 50%;
  bottom: 0;
  content: ' ';
  left: 50%;
  margin-left: -250%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 500%;
  z-index: 1;
}

.price {
  background-color: #f5f5f5;
  height: 25px;
  max-width: 30%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.price::after {
  -webkit-animation: ph-animation 0.8s linear infinite;
  animation: ph-animation 0.8s linear infinite;
  /* background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, 0.35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%; */
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 46%,
      hsl(0deg 0% 91.97% / 35%) 50%,
      hsla(0, 0%, 100%, 0) 54%
    )
    50% 50%;
  bottom: 0;
  content: ' ';
  left: 50%;
  margin-left: -250%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 500%;
  z-index: 1;
}

.description {
  background-color: #f5f5f5;
  height: 39px;
  max-width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.description::after {
  -webkit-animation: ph-animation 0.8s linear infinite;
  animation: ph-animation 0.8s linear infinite;
  /* background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, 0.35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%; */
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 46%,
      hsl(0deg 0% 91.97% / 35%) 50%,
      hsla(0, 0%, 100%, 0) 54%
    )
    50% 50%;
  bottom: 0;
  content: ' ';
  left: 50%;
  margin-left: -250%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 500%;
  z-index: 1;
}

.title {
  background-color: #ededed;
  height: 47px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.title::after {
  -webkit-animation: ph-animation 0.8s linear infinite;
  animation: ph-animation 0.8s linear infinite;
  /* background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, 0.35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%; */
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 46%,
      hsl(0deg 0% 91.97% / 35%) 50%,
      hsla(0, 0%, 100%, 0) 54%
    )
    50% 50%;
  bottom: 0;
  content: ' ';
  left: 50%;
  margin-left: -250%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 500%;
  z-index: 1;
}

.category {
  background-color: #f5f5f5;
  height: 15px;
  max-width: 40%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.category::after {
  -webkit-animation: ph-animation 0.8s linear infinite;
  animation: ph-animation 0.8s linear infinite;
  /* background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, 0.35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%; */
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 46%,
      hsl(0deg 0% 91.97% / 35%) 50%,
      hsla(0, 0%, 100%, 0) 54%
    )
    50% 50%;
  bottom: 0;
  content: ' ';
  left: 50%;
  margin-left: -250%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 500%;
  z-index: 1;
}

.body .rating {
  height: 23px;
  width: 181px;
  border-radius: 23px;
  background-color: #d9d9d9;
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: auto;
}
.body::after {
  -webkit-animation: ph-animation 0.8s linear infinite;
  animation: ph-animation 0.8s linear infinite;
  /* background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, 0.35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%; */
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 46%,
      hsl(0deg 0% 91.97% / 35%) 50%,
      hsla(0, 0%, 100%, 0) 54%
    )
    50% 50%;
  bottom: 0;
  content: ' ';
  left: 50%;
  margin-left: -250%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 500%;
  z-index: 1;
}
.body {
  background-color: #f5f5f5;
  border-radius: 12px;
  height: 280px;
  position: relative;
  overflow: hidden;
}

.body {
  background-color: #f5f5f5;
  border-radius: 12px;
  height: 20vh;
  position: relative;
  overflow: hidden;
}

@media (min-width: 992px) {
  .body {
    height: 280px;
  }
}
</style>
