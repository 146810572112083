/*eslint-disable*/

import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import HomePage from '../views/HomePage/HomePageView.vue';

const routes = [
  {
    path: '/mentenanta',
    name: 'Maintenance',
    component: () => import('../views/MaintenanceView.vue'),
  },
  {
    path: '/',
    name: 'Layout',
    component: () => import('../views/Layout/LayoutView.vue'),
    children: [
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFoundPage',
        component: () => import('../views/Layout/NoPageFoundView.vue'),
        meta: {
          label: '',
        },
      },
      {
        path: '',
        name: 'HomePage',
        component: HomePage, // () => import('../views/HomePage/HomePageView.vue'),
        meta: {
          label: 'Acasă',
        },
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact/ContactView.vue'),
        meta: {
          label: 'Contactează-ne',
        },
      },
      {
        path: '/produse/:categoryName?/:categoryId?',
        name: 'Products',
        component: () => import('../views/Products/ProductsView.vue'),
        meta: {
          label: 'Produse',
        },
      },
      {
        path: '/blog/:categoryName?/:categoryId?',
        name: 'Blogs',
        component: () => import('../views/Blogs/BlogsView.vue'),
        meta: {
          label: 'Articole',
        },
      },
      {
        path: '/articol/:articleName/:articolIdParam',
        name: 'Article',
        component: () => import('../views/Blogs/ArticleView.vue'),
        props: true,
        meta: {
          label: 'Articol',
        },
      },
      {
        path: '/produs/:productName/:productIdParam?',
        name: 'Product',
        component: () => import('../views/Products/ProductView.vue'),
        props: true,
        meta: {
          label: 'Produs',
        },
      },
      {
        path: '/cosul-de-cumparaturi/multumim/:orderId/:pickUpDate',
        name: 'ThanksForOrder',
        component: () => import('../views/Cart/ThanksForOrderView.vue'),
        props: true,
        meta: {
          label: 'Mulțumim',
        },
      },
      {
        path: '/cosul-de-cumparaturi',
        name: 'Cart',
        component: () => import('../views/Cart/CartView.vue'),
        meta: {
          label: 'Coș',
        },
      },
      {
        path: '/profilul-utilizatorului',
        name: 'UserProfile',
        component: () => import('../views/User/UserProfileView.vue'),
        meta: { requiresAuth: true, label: 'Profilul tău' },
      },
      {
        path: '/politica-cookie',
        name: 'CookiePolicy',
        component: () => import('../views/TermsPages/CookiePolicyView.vue'),
        meta: {
          label: 'Politică cookie',
        },
      },
      {
        path: '/politica-de-confidentialitate',
        name: 'PrivacyPolicy',
        component: () => import('../views/TermsPages/PrivacyPolicyView.vue'),
        meta: {
          label: 'Politică conf.',
        },
      },
      {
        path: '/termeni-si-conditii',
        name: 'TermsAndConditions',
        component: () => import('../views/TermsPages/TermsConditionsView.vue'),
        meta: {
          label: 'Termeni și condiții',
        },
      },
      {
        path: '/VerifyEmail',
        name: 'VerifyEmailDashboard',
        component: () => import('../views/Auth/VerifyEmailView.vue'),
        props: true,
        meta: {
          label: 'Verificare email',
        },
      },
      {
        path: '/verificare-email',
        name: 'VerifyEmail',
        component: () => import('../views/Auth/VerifyEmailView.vue'),
        props: true,
        meta: {
          label: 'Verificare email',
        },
      },
      {
        path: '/ChangePassword',
        name: 'ChangePassword',
        component: () => import('../views/Auth/ChangePasswordView.vue'),
        props: true,
        meta: {
          label: 'Schimbare parolă',
        },
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if (to.path !== from.path) {
      return { top: 0 };
    }
    return null;
  },
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.name !== 'Products' && to.path !== from.path) {
  //     return { top: 0 };
  //   }
  //   return savedPosition;
  // },
});
router.beforeEach((to, from, next) => {
  if (to.name !== 'Maintenance' && store.state.websiteInMaintenance) {
    next({
      name: 'Maintenance',
    });
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.status.loggedIn) {
      next();
      return;
    }
    next({ name: 'HomePage' });
  } else {
    next();
  }
});

router.beforeResolve(async (to, from, next) => {
  if (to.path.includes('/colectii/prajituri')) next((to = { name: 'Products' }));
  next();
});

export default router;
