<template>
  <div v-if="cookieConsent == null && showCookiePopup">
    <div class="cookie-background"></div>
    <div class="cookie-popup">
      <div class="card m-2 m-sm-5 list-scrollable">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col">
              <div class="heading-04 text-secondary-05">
                <span class="d-sm-none">Acord de Utilizare a Cookie-urilor</span>
                <span class="d-none d-sm-block"
                  >Acord de Confidențialitate și Utilizare a Cookie-urilor</span
                >
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="button close"
                @click="showCookiePopup = false"
                aria-labelledby="Close cookie popup"
                aria-label="Close cookie popup"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 15L15 5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 15L5 5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col mt-3 mb-3 content">
              <span class="d-md-none">
                Acest modul de acceptare a politicii de cookies reprezintă angajamentul nostru față
                de protecția datelor tale și transparența în utilizarea cookie-urilor.
              </span>
              <span class="d-none d-md-block d-xxl-none">
                Acest modul de acceptare a politicii de cookies reprezintă angajamentul nostru față
                de protecția datelor tale și transparența în utilizarea cookie-urilor. Prin
                acceptarea acestui acord, îți oferi consimțământul pentru utilizarea cookie-urilor
                conform politicii noastre de confidențialitate. Cookie-urile sunt folosite pentru a
                îmbunătăți experiența ta pe site-ul nostru, pentru analiza traficului și pentru
                personalizarea conținutului.
              </span>
              <span class="d-none d-xxl-block">
                Acest modul de acceptare a politicii de cookies reprezintă angajamentul nostru față
                de protecția datelor tale și transparența în utilizarea cookie-urilor. Prin
                acceptarea acestui acord, îți oferi consimțământul pentru utilizarea cookie-urilor
                conform politicii noastre de confidențialitate. Cookie-urile sunt folosite pentru a
                îmbunătăți experiența ta pe site-ul nostru, pentru analiza traficului și pentru
                personalizarea conținutului. Poți gestiona preferințele cookie-urilor în orice
                moment din setările contului tău. Pentru mai multe informații, te rugăm să consulți
                politica noastră de confidențialitate și politica privind cookie-urile.
              </span>
            </div>
            <div class="col-auto d-none d-md-block">
              <img
                src="../../assets/images/cookie-consent-right-img.webp"
                class="right-img"
                width="99"
                height="89"
                alt="Cookie consent"
              />
            </div>
            <div class="col-auto d-md-none">
              <img
                src="../../assets/images/cookie-consent-right-img.webp"
                class="right-img"
                alt="Cookie consent"
              />
            </div>
          </div>
          <div class="row align-items-center justify-content-center justify-content-sm-start">
            <div class="col-auto">
              <button
                type="button"
                class="button btn-medium-wider btn-primary-solid"
                @click="cookieConsent = true"
              >
                Acceptă
              </button>
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="button btn-medium-wider btn-neutral-outlined"
                @click="cookieConsent = false"
              >
                Refuză
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  data() {
    return {
      showCookiePopup: true,
      get cookieConsent() {
        return localStorage.getItem('cookieConsent');
      },
      set cookieConsent(value) {
        localStorage.setItem('cookieConsent', value);
      },
    };
  },
  methods: {},
};
</script>

<style scoped>
.button.close {
  filter: invert(89%) sepia(10%) saturate(6%) hue-rotate(324deg) brightness(90%) contrast(92%);
  transition: 0.2s;
}
.button.close:hover {
  filter: invert(28%) sepia(64%) saturate(1673%) hue-rotate(308deg) brightness(100%) contrast(97%);
}
.cookie-popup .card {
  max-height: 350px;
  max-width: 1350px;
  background-color: #fff;
  border-radius: 8px;
  margin: auto;
  overflow-y: auto;
}
.cookie-popup {
  position: fixed;
  bottom: 0;
  z-index: 6000;
  width: 100%;
  display: flex;
  justify-content: center;
}
.cookie-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #00000066;
  top: 0;
  z-index: 6000;
  opacity: 0.5;
}
.content {
  color: var(--neutral-8);
  font-size: 11px;
}
.heading-04 {
  font-size: 16px;
}
@media only screen and (max-width: 768px) {
  .right-img {
    max-width: 10vw;
  }
  .heading-04 {
    line-height: 25px;
  }
}
.cookie-popup .card .card-body {
  padding: 24px;
}
@media only screen and (min-width: 576px) {
  .heading-04 {
    font-size: 22px;
  }
  .content {
    font-size: 14px;
  }
  .cookie-popup .card .card-body {
    padding: 32px;
  }
}
@media only screen and (min-width: 768px) {
  .heading-04 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 992px) {
  .heading-04 {
    font-size: 32px;
  }
  .content {
    font-size: 16px;
  }
}
</style>
