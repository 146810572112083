<template>
  <cookie-popup-component :key="new Date()" />
  <div v-if="this.$store.state.loader" class="page-loader-background"></div>
  <div v-if="this.$store.state.loader" class="page-loader-middle">
    <span class="loader"></span>
  </div>
  <!-- v-if="this.$store.state.loader" -->
  <router-view />
</template>

<script>
import { Tooltip } from 'bootstrap';
import CookiePopupComponent from './components/General/CookiePopupComponent.vue';

export default {
  components: { CookiePopupComponent },
  name: 'App',
  mounted() {
    // eslint-disable-next-line no-new
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
      html: true,
    });
  },
  computed: {
    loader() {
      return this.$store.state.loader;
    },
  },
  watch: {
    // loader: {
    //   handler(value) {
    //     // eslint-disable-next-line no-unused-expressions
    //     value
    //       ? ((document.body.style.overflowY = 'hidden'), (document.body.style.position = 'fixed'))
    //       : ((document.body.style.overflowY = 'visible'),
    //       (document.body.style.position = 'relative'));
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>
<style scoped>
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid var(--primary-5);
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.page-loader-middle {
  z-index: 1501;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  /* this is what centers your element in the fixed wrapper*/
  display: flex;
  flex-flow: column nowrap;
  justify-content: center; /* aligns on vertical for column */
  align-items: center; /* aligns on horizontal for column */
}
.page-loader-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #7d7d7d;
  top: 0;
  left: 0;
  z-index: 1500;
  opacity: 0.5;
}
</style>
